import * as React from "react";

import { useLoaderData } from "react-router-dom";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import AppBar from "../../../components/Layouts/AppBar";
import useCustomQuery from "../../../network/common/useCustomQuery";
import devicesQuery from "../../../network/devices/devices.query";
import { HomeLoaderData } from "../../../network/home/home.loader";
import networksQuery from "../../../network/networks/networks.query";
import HomeCard from "./HomeCard";

export default function HomePage() {
  const initialData = useLoaderData() as HomeLoaderData;
  const { data: devicesData } = useCustomQuery(devicesQuery(), {
    initialData: initialData.devices,
  });
  const { data: newtorksData } = useCustomQuery(networksQuery(), {
    initialData: initialData.networks,
  });

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        height: "100%",
      }}
    >
      <AppBar title="Accueil" />
      <Stack spacing={4} direction="row" sx={{ padding: 4 }}>
        <HomeCard
          primary={`${newtorksData.data.length}`}
          secondary="Serveurs"
        />
        <HomeCard
          primary={`${devicesData.data.length}`}
          secondary="Appareils"
        />
      </Stack>
    </Box>
  );
}
