import axios, { AxiosError } from "axios";

import { ApiError } from "../../interfaces/ApiError";
import Devices from "../../interfaces/Devices";
import { axiosErrorToArray } from "../../utils/axiosErrorToArray";
import { generateAxiosConfig } from "../common/useCustomQuery";

export default async function downloadMultipleConfig(device: Devices) {
  let res: any;
  try {
    res = await axios(
      generateAxiosConfig({
        method: "post",
        url: `devices/config`,
        responseType: "blob",
        data: {
          devices: device.map((d) => d.id),
        },
      })
    );
  } catch (err) {
    if (err instanceof AxiosError<ApiError, any>) {
      return await axiosErrorToArray(err);
    }
    throw err;
  }
  const filename = "config.zip";
  // create file link in browser's memory
  const href = URL.createObjectURL(res.data);

  // create "a" HTML element with href to file & click
  const link = document.createElement("a");
  link.href = href;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();

  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
  URL.revokeObjectURL(href);

  // Return no errors
  return [];
}
