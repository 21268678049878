import axios, { AxiosResponse } from "axios";

import { QueryClient, QueryKey } from "@tanstack/react-query";

import { generateAxiosConfig,QueryReturnType } from "./useCustomQuery";

export default function fetchCustomQuery<TQueryKey extends QueryKey, TData>(
  queryClient: QueryClient,
  queryConfig: QueryReturnType<TQueryKey, TData>
): Promise<AxiosResponse<TData>> {
  const { key, config } = queryConfig;

  const finalConfig = generateAxiosConfig(config);

  return queryClient.fetchQuery<
    AxiosResponse<TData>,
    unknown,
    AxiosResponse<TData>
  >(key, () => axios(finalConfig));
}
