import { createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface Palette {
    back: Palette["primary"];
  }
  interface PaletteOptions {
    back: PaletteOptions["primary"];
  }
}

export const theme = createTheme({
  palette: {
    primary: {
      main: "#487bff",
      //   light: "...",
      //   dark: "...",
      contrastText: "#fff",
    },
    back: {
      main: "#f2f2f2",
      contrastText: "#777",
    },
  },
});
