import * as React from "react";

import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

import { yupResolver } from "@hookform/resolvers/yup";
import { useQueryClient } from "@tanstack/react-query";

import ErrorBox from "../../../components/ErrorBox/ErrorBox";
import { Organization } from "../../../interfaces/Organizations";
import useCustomMutation from "../../../network/common/useCustomMutation";
import actionsQuery from "../../../network/configuration/actions.query";
import createOrganizationMutation from "../../../network/organizations/create.mutation";
import organizationsQuery from "../../../network/organizations/organizations.query";
import updateOrganizationMutation from "../../../network/organizations/update.mutation";
import { axiosErrorToArray } from "../../../utils/axiosErrorToArray";

interface FormData {
  name: string;
}
const validationSchema = Yup.object().shape({
  name: Yup.string().required("Un nom est requis"),
});

interface OrganizationFormProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  organization?: Organization;
}
export default function OrganizationForm({
  open,
  setOpen,
  organization,
}: OrganizationFormProps) {
  const { handleSubmit, reset, control, setValue } = useForm<FormData>({
    resolver: yupResolver(validationSchema),
  });

  const [errors, setErrors] = React.useState<string[]>([]);
  const queryClient = useQueryClient();
  const createMutation = useCustomMutation(createOrganizationMutation);
  const updateMutation = useCustomMutation(updateOrganizationMutation);

  const onSubmit = (data: FormData) => {
    if (organization === undefined) {
      createMutation.mutate(data, {
        onSuccess: (data) => {
          queryClient.invalidateQueries({ queryKey: organizationsQuery().key });
          queryClient.invalidateQueries({ queryKey: actionsQuery().key });
          handleClose();
        },
        onError: async (error) => {
          setErrors(await axiosErrorToArray(error));
        },
      });
    } else {
      updateMutation.mutate(
        { id: organization.id, ...data },
        {
          onSuccess: (data) => {
            queryClient.invalidateQueries({
              queryKey: organizationsQuery().key,
            });
            queryClient.invalidateQueries({ queryKey: actionsQuery().key });
            handleClose();
          },
          onError: async (error) => {
            setErrors(await axiosErrorToArray(error));
          },
        }
      );
    }
  };

  const handleClose = () => {
    setErrors([]);
    reset();
    setOpen(false);
  };

  React.useEffect(() => {
    if (organization === undefined) {
      setValue("name", "");
    } else {
      setValue("name", organization.name);
    }
  }, [organization, setValue]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>
        {organization === undefined
          ? "Ajout d'une organisation"
          : "Modification d'une organisation"}
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Controller
                name="name"
                control={control}
                defaultValue={
                  organization === undefined ? "" : organization.name
                }
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    required
                    label="Nom"
                    fullWidth
                    margin="dense"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <ErrorBox errors={errors} setErrors={setErrors} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Annuler
          </Button>
          <Button type="submit" autoFocus>
            {organization === undefined ? "Ajouter" : "Modifier"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
