import { AxiosRequestConfig } from "axios";

import Networks from "../../interfaces/Networks";
import { QueryReturnType } from "../common/useCustomQuery";

export default function networksQuery(): QueryReturnType<string[], Networks> {
  const config: AxiosRequestConfig = {
    method: "get",
    url: "/networks",
  };
  return { key: ["networks"], config };
}
