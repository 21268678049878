import { AxiosRequestConfig } from "axios";

import Devices from "../../interfaces/Devices";
import { QueryReturnType } from "../common/useCustomQuery";

export default function devicesQuery(): QueryReturnType<string[], Devices> {
  const config: AxiosRequestConfig = {
    method: "get",
    url: "/devices",
  };
  return { key: ["devices"], config };
}
