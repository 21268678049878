import { useCallback } from "react";

import axios, { AxiosError,AxiosRequestConfig, AxiosResponse } from "axios";

import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";

import { config as apiConfig } from "../../utils/config";

// Disable the same warning as in useCustomQuery
// eslint-disable-next-line
export interface MutationReturnType<TData = void, TError = void> {
  config: AxiosRequestConfig;
}

function useCustomMutation<TVar, TData = void, TError = void>(
  mutation: (vars: TVar) => MutationReturnType<TData, TError>,
  options?: UseMutationOptions<
    AxiosResponse<TData>,
    AxiosError<TError>,
    TVar,
    unknown
  >
): UseMutationResult<AxiosResponse<TData>, AxiosError<TError>, TVar, unknown> {
  const mutationFn = useCallback(
    (variables: TVar) => {
      const { config } = mutation(variables);
      return axios({
        ...config,
        baseURL: apiConfig.API_ROOT,
        withCredentials: true,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
    },
    [mutation]
  );

  return useMutation(mutationFn, options);
}

export default useCustomMutation;
