import { QueryClient } from "@tanstack/react-query";

import fetchCustomQuery from "../common/fetchCustomQuery";
import getCustomQueryData from "../common/getCustomQueryData";
import networksQuery from "./networks.query";

export default function networksLoader(queryClient: QueryClient) {
  return async () => {
    const query = networksQuery();
    return (
      getCustomQueryData(queryClient, query) ??
      (await fetchCustomQuery(queryClient, query))
    );
  };
}

export type NetworksLoaderData = Awaited<
  ReturnType<ReturnType<typeof networksLoader>>
>;
