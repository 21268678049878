export default function filterArray<T>(filter: string, array: T[]) {
  return array.filter((o) => {
    for (const key in o) {
      if (String(o[key]).toLowerCase().includes(filter.toLowerCase())) {
        return true;
      }
    }
    return false;
  });
}
