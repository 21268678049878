import * as React from "react";

import { useLoaderData } from "react-router-dom";

import Alert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import List from "@mui/material/List";
import { useTheme } from "@mui/material/styles";

import { useQueryClient } from "@tanstack/react-query";

import ErrorBox from "../../../components/ErrorBox/ErrorBox";
import AppBar from "../../../components/Layouts/AppBar";
import useCustomMutation from "../../../network/common/useCustomMutation";
import useCustomQuery from "../../../network/common/useCustomQuery";
import actionsQuery from "../../../network/configuration/actions.query";
import applyConfigurationMutation from "../../../network/configuration/apply.mutation";
import { ActionsLoaderData } from "../../../network/configuration/configuration.loader";
import organizationsQuery from "../../../network/organizations/organizations.query";
import { axiosErrorToArray } from "../../../utils/axiosErrorToArray";
import ActionDetails from "./ActionDetail";

export default function ConfigurationPage() {
  const theme = useTheme();

  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [errors, setErrors] = React.useState<string[]>([]);

  const initialData = useLoaderData() as ActionsLoaderData;
  const { data } = useCustomQuery(actionsQuery(), { initialData });
  const queryClient = useQueryClient();

  const mutation = useCustomMutation(applyConfigurationMutation);
  const apply = () => {
    setOpenBackdrop(true);
    mutation.mutate(
      { id: data.data.id },
      {
        onSuccess: () => {
          setOpenBackdrop(false);
          queryClient.invalidateQueries({
            queryKey: actionsQuery().key,
          });
          queryClient.invalidateQueries({ queryKey: organizationsQuery().key });
        },
        onError: async (err) => {
          setOpenBackdrop(false);
          setErrors(await axiosErrorToArray(err));
        },
      }
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <AppBar
        title="Configuration"
        action={
          <Button
            variant="contained"
            onClick={apply}
            disabled={data.data.actions.length === 0}
          >
            Appliquer
          </Button>
        }
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
        }}
      >
        <List
          sx={{
            backgroundColor: "back.main",
          }}
        >
          {data.data.actions.map((action, num) => (
            <ActionDetails
              action={action}
              key={num}
              sx={{ margin: theme.spacing(1) }}
            />
          ))}
        </List>
        <Box sx={{ flexGrow: 1 }} />
        <ErrorBox errors={errors} setErrors={setErrors} />

        <Alert
          severity="info"
          sx={{ margin: theme.spacing(2, 0, 0, 0) }}
          action={
            data.data.actions.length !== 0 ? (
              <Button onClick={apply}>Appliquer</Button>
            ) : undefined
          }
        >
          {`${data.data.actions.length} action${
            data.data.actions.length > 1 ? "s" : ""
          } en attente`}
        </Alert>
      </Box>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
}
