import { AxiosRequestConfig } from "axios";

import Actions from "../../interfaces/Actions";
import { QueryReturnType } from "../common/useCustomQuery";

interface ActionResponseInterface {
  id: number;
  actions: Actions;
}
export default function actionsQuery(): QueryReturnType<
  string[],
  ActionResponseInterface
> {
  const config: AxiosRequestConfig = {
    method: "get",
    url: "/configuration/actions",
  };
  return { key: ["actions"], config };
}
