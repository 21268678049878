import * as React from "react";

import SearchIcon from "@mui/icons-material/Search";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";

interface SearchInputProps {
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
}

export default function SearchInput({ value, onChange }: SearchInputProps) {
  return (
    <FormControl>
      <InputLabel htmlFor="search-field">Filtrer</InputLabel>
      <OutlinedInput
        id="search-field"
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        }
        label="Filtrer"
        value={value}
        onChange={onChange}
      />
    </FormControl>
  );
}
