import * as React from "react";

import { Link, useMatch, useResolvedPath } from "react-router-dom";

import DnsIcon from "@mui/icons-material/Dns";
import GroupsIcon from "@mui/icons-material/Groups";
import HomeIcon from "@mui/icons-material/Home";
import LogoutIcon from "@mui/icons-material/Logout";
import RouterIcon from "@mui/icons-material/Router";
import SettingsIcon from "@mui/icons-material/Settings";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import type { LinkProps } from "react-router-dom";

const drawerWidth = 300;

interface CustomLinkProps extends LinkProps {
  icon: JSX.Element;
  text: string;
}

function CustomLink({ children, to, icon, text, ...props }: CustomLinkProps) {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });

  return (
    <Link style={{ textDecoration: "none" }} to={to} {...props}>
      <ListItem key={text}>
        <ListItemButton
          sx={{
            backgroundColor: match ? "rgba(0,0,0,0.1)" : "none",
            borderRadius: "5px",
          }}
        >
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText
            primary={text}
            primaryTypographyProps={{
              color: "primary.contrastText",
              fontSize: "1.3rem",
            }}
          />
        </ListItemButton>
      </ListItem>
    </Link>
  );
}

export default function NavBar() {
  const theme = useTheme();

  const iconStyle = { color: "primary.contrastText", fontSize: "2rem" };
  const topMenu = [
    {
      icon: <HomeIcon sx={iconStyle} />,
      text: "Accueil",
      link: "/",
    },
    {
      icon: <GroupsIcon sx={iconStyle} />,
      text: "Organisations",
      link: "/organizations",
    },
    {
      icon: <DnsIcon sx={iconStyle} />,

      text: "Réseaux",
      link: "/networks",
    },
    {
      icon: <RouterIcon sx={iconStyle} />,
      text: "Appareils",
      link: "devices",
    },
  ];

  const bottomMenu = [
    {
      icon: <SettingsIcon sx={iconStyle} />,
      text: "Configuration",
      link: "/configuration",
    },
    {
      icon: <LogoutIcon sx={iconStyle} />,
      text: "Déconnexion",
      link: "/logout",
    },
  ];

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
          backgroundColor: "primary.main",
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Typography
        variant="h1"
        sx={{
          width: "100%",
          textAlign: "center",
          fontSize: "2rem",
          fontWeight: 400,
          color: "primary.contrastText",
          padding: theme.spacing(2),
        }}
      >
        OpenVPN Manager
      </Typography>
      <Divider />
      <List>
        {topMenu.map(({ icon, text, link }) => (
          <CustomLink to={link} text={text} icon={icon} key={link} />
        ))}
      </List>
      <Box sx={{ flexGrow: 1 }} />
      <Divider />
      <List>
        {bottomMenu.map(({ icon, text, link }) => (
          <CustomLink to={link} text={text} icon={icon} key={link} />
        ))}
      </List>
    </Drawer>
  );
}
