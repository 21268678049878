import { QueryClient } from "@tanstack/react-query";

import fetchCustomQuery from "../common/fetchCustomQuery";
import getCustomQueryData from "../common/getCustomQueryData";
import devicesQuery from "./devices.query";

export default function devicesLoader(queryClient: QueryClient) {
  return async () => {
    const query = devicesQuery();
    return (
      getCustomQueryData(queryClient, query) ??
      (await fetchCustomQuery(queryClient, query))
    );
  };
}

export type DevicesLoaderData = Awaited<
  ReturnType<ReturnType<typeof devicesLoader>>
>;
