import { AxiosError, AxiosResponse } from "axios";

import { UseMutationResult } from "@tanstack/react-query";

import { ApiError } from "../interfaces/ApiError";
import { axiosErrorToArray } from "./axiosErrorToArray";

export async function deleteMultipleIds(
  mutation: UseMutationResult<
    AxiosResponse<void>,
    AxiosError<ApiError>,
    { id: number },
    unknown
  >,
  name: string,
  ids: readonly number[]
) {
  let mutationErrors: string[] = [];
  for (const id of ids) {
    try {
      await mutation.mutateAsync(
        { id },
        {
          onError: async (error) => {
            (await axiosErrorToArray(error))
              .map((m) => `${name} ${id} : ${m}`)
              .forEach((v) => mutationErrors.push(v));
          },
        }
      );
    } catch (error) {}
  }
  return mutationErrors;
}
