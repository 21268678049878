import * as React from "react";

import axios from "axios";
import { decode as base64_decode } from "base-64";

interface AuthContextInterface {
  token: string;
  setToken: (token: string) => void;
}

const AuthContext = React.createContext<AuthContextInterface>({
  token: "",
  setToken: (token: string) => {},
});

interface JWT_DATA {
  username: string;
  sub: number;
  iat: number;
  exp: number;
}
export const AuthProvider = ({
  savedToken,
  children,
}: {
  savedToken: string;
  children: React.ReactNode;
}) => {
  try {
    if (savedToken) {
      // Invalidate the token if it expires too soon
      const jwtPayload: JWT_DATA = JSON.parse(
        base64_decode(savedToken.split(".")[1])
      );
      const expiration = new Date(jwtPayload.exp);
      const now = new Date();
      const fiveMinutes = 1000 * 60 * 5;
      if (expiration.getTime() * 1000 - now.getTime() < fiveMinutes) {
        // Token expires soon, remove it
        savedToken = "";
        localStorage.removeItem("token");
      }
    }
  } catch (error) {
    // Token is invalid, remove it
    localStorage.removeItem("token");
  }

  const [token, setToken] = React.useState(savedToken);

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        localStorage.removeItem("token");
        setToken("");
      }
      return Promise.reject(error);
    }
  );

  return (
    <AuthContext.Provider value={{ token, setToken }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => React.useContext(AuthContext);
