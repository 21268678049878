import * as React from "react";

import { useLoaderData } from "react-router-dom";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import { useQueryClient } from "@tanstack/react-query";

import DeleteConfirmationDialog from "../../../components/Dialogs/DeleteConfirmationDialog";
import EntityGrid, {
  HeadCell,
} from "../../../components/EntityGrid/EntityGrid";
import EntityGridToolbar from "../../../components/EntityGrid/EntityGridToolbar";
import ErrorBox from "../../../components/ErrorBox/ErrorBox";
import useCustomMutation from "../../../network/common/useCustomMutation";
import useCustomQuery from "../../../network/common/useCustomQuery";
import actionsQuery from "../../../network/configuration/actions.query";
import deleteOrganizationMutation from "../../../network/organizations/delete.mutation";
import { OrganizationsLoaderData } from "../../../network/organizations/organizations.loader";
import organizationsQuery from "../../../network/organizations/organizations.query";
import { deleteMultipleIds } from "../../../utils/deleteMultipleIds";
import filterArray from "../../../utils/filterArray";
import OrganizationForm from "./OrganizationForm";

const columns: HeadCell<{
  id: number;
  name: string;
  grafanaDashboardUrl: string;
  grafanaUserLogin: string;
  grafanaUserPassword: string;
}>[] = [
  { id: "id", label: "ID" },
  { id: "name", label: "Nom" },
  { id: "grafanaDashboardUrl", label: "Dashboard" },
  { id: "grafanaUserLogin", label: "Utilisateur Grafana" },
  { id: "grafanaUserPassword", label: "Mot de passe Grafana" },
];

export default function OrganizationsPage() {
  const initialData = useLoaderData() as OrganizationsLoaderData;
  const { data } = useCustomQuery(organizationsQuery(), { initialData });
  const queryClient = useQueryClient();

  const [selected, setSelected] = React.useState<readonly number[]>([]);
  const [rows, setRows] = React.useState(data.data);

  const [openDialog, setOpenDialog] = React.useState(false);

  const [errors, setErrors] = React.useState<string[]>([]);

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleEdit = () => {
    setOpenDialog(true);
  };

  // Delete
  const [openDeleteDialog, setopenDeleteDialog] = React.useState(false);
  const deleteMutation = useCustomMutation(deleteOrganizationMutation);
  const handleDelete = () => {
    setopenDeleteDialog(true);
  };
  const deleteIds = async (ids: readonly number[]) => {
    const mutationErrors = await deleteMultipleIds(
      deleteMutation,
      "Organisation",
      ids
    );
    setErrors(mutationErrors);
    queryClient.invalidateQueries({
      queryKey: organizationsQuery().key,
    });
    queryClient.invalidateQueries({
      queryKey: actionsQuery().key,
    });
    setSelected([]);
  };

  // Filter
  const [filter, setFilter] = React.useState("");
  const onFilterChanged = (filter: string) => {
    setFilter(filter);
  };
  React.useEffect(() => {
    setRows(filterArray(filter, data.data));
  }, [filter, data]);

  return (
    <Stack flexGrow={1}>
      <EntityGridToolbar
        name="Organisations"
        selectedIDs={selected}
        onCreate={handleClickOpen}
        onEdit={handleEdit}
        onDelete={handleDelete}
        filter={filter}
        setFilter={onFilterChanged}
      />

      <EntityGrid
        rows={rows.map((o) => {
          return { ...o };
        })}
        columns={columns}
        defaultSortingColumn="id"
        selected={selected}
        setSelected={setSelected}
      />

      <Box sx={{ flexGrow: 1 }}></Box>

      <ErrorBox errors={errors} setErrors={setErrors} />

      <OrganizationForm
        open={openDialog}
        setOpen={setOpenDialog}
        organization={
          selected.length === 1
            ? data.data.find((o) => o.id === selected[0])
            : undefined
        }
      />

      <DeleteConfirmationDialog
        idsToDelete={selected}
        deleteIds={deleteIds}
        open={openDeleteDialog}
        setOpen={setopenDeleteDialog}
      />
    </Stack>
  );
}
