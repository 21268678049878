import { QueryClient } from "@tanstack/react-query";

import fetchCustomQuery from "../common/fetchCustomQuery";
import getCustomQueryData from "../common/getCustomQueryData";
import organizationsQuery from "./organizations.query";

export default function organizationsLoader(queryClient: QueryClient) {
  return async () => {
    const query = organizationsQuery();
    return (
      getCustomQueryData(queryClient, query) ??
      (await fetchCustomQuery(queryClient, query))
    );
  };
}

export type OrganizationsLoaderData = Awaited<
  ReturnType<ReturnType<typeof organizationsLoader>>
>;
