import * as React from "react";

import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

interface AppBarProps {
  title: string;
  action?: React.ReactNode;
}
export default function AppBar({ title, action }: AppBarProps) {
  return (
    <Stack sx={{ mt: 2 }}>
      <Grid
        container
        spacing={1}
        sx={{
          padding: 1,
        }}
      >
        <Grid
          item
          xs={4}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "baseline",
          }}
        ></Grid>
        <Grid
          item
          xs={4}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "baseline",
          }}
        >
          <Typography
            variant="h2"
            sx={{
              flexGrow: 1,
              fontSize: "2rem",
              color: "text.secondary",
              fontWeight: 600,
              textAlign: "center",
            }}
          >
            {title}
          </Typography>
        </Grid>
        <Grid
          item
          xs={4}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "baseline",
          }}
        >
          {action}
        </Grid>
      </Grid>
      <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
    </Stack>
  );
}
