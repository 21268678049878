import * as React from "react";

import { CssBaseline, ThemeProvider } from "@mui/material";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { AuthProvider } from "./contexts/AuthContext";
import RouterProvider from "./routes/RouterProvider";
import { theme } from "./utils/theme";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60000, // 1 minute
    },
  },
});

function App() {
  const savedToken = localStorage.getItem("token") ?? "";

  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <AuthProvider savedToken={savedToken}>
            {/* To ease development import @tanstack/react-query-devtools and uncomment the following line */}
            {/* <ReactQueryDevtools initialIsOpen={false} /> */}
            <RouterProvider />
          </AuthProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
