import { AxiosRequestConfig } from "axios";

import PortRange from "../../interfaces/PortRange";
import { QueryReturnType } from "../common/useCustomQuery";

export default function portRangeQuery(): QueryReturnType<string[], PortRange> {
  const config: AxiosRequestConfig = {
    method: "get",
    url: "/networks/portRange",
  };
  return { key: ["portRange"], config };
}
