import { QueryClient } from "@tanstack/react-query";

import fetchCustomQuery from "../common/fetchCustomQuery";
import getCustomQueryData from "../common/getCustomQueryData";
import actionsQuery from "./actions.query";

export default function configurationLoader(queryClient: QueryClient) {
  return async () => {
    const query = actionsQuery();
    return (
      getCustomQueryData(queryClient, query) ??
      (await fetchCustomQuery(queryClient, query))
    );
  };
}

export type ActionsLoaderData = Awaited<
  ReturnType<ReturnType<typeof configurationLoader>>
>;
