import { AxiosRequestConfig } from "axios";

import Organizations from "../../interfaces/Organizations";
import { QueryReturnType } from "../common/useCustomQuery";

export default function organizationsQuery(): QueryReturnType<
  string[],
  Organizations
> {
  const config: AxiosRequestConfig = {
    method: "get",
    url: "/organizations",
  };
  return { key: ["organizations"], config };
}
