import * as React from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import SearchInput from "./SearchInput";

interface EntityGridToolbarProps {
  selectedIDs: readonly number[];
  name: string;
  onCreate: () => void;
  onEdit: () => void;
  onDelete: () => void;

  filter: string;
  setFilter: (filter: string) => void;

  additionalActions?: React.ReactNode;
}

export default function EntityGridToolbar({
  selectedIDs,
  name,
  onCreate,
  onEdit,
  onDelete,
  filter,
  setFilter,
  additionalActions,
}: EntityGridToolbarProps) {
  const numSelected = selectedIDs.length;

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value);
  };

  return (
    <Stack sx={{ mt: 2 }}>
      <Grid
        container
        spacing={1}
        sx={{
          padding: 1,
        }}
      >
        <Grid
          item
          xs={4}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "baseline",
          }}
        >
          <SearchInput value={filter} onChange={onChange} />
          {numSelected > 0 ? (
            <Chip
              label={`${numSelected} selectionné${numSelected > 1 ? "s" : ""}`}
              color="primary"
              sx={{ ml: 1 }}
            />
          ) : (
            <></>
          )}
        </Grid>
        <Grid
          item
          xs={4}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "baseline",
          }}
        >
          <Typography
            variant="h2"
            sx={{
              flexGrow: 1,
              fontSize: "2rem",
              color: "text.secondary",
              fontWeight: 600,
              textAlign: "center",
            }}
          >
            {name}
          </Typography>
        </Grid>
        <Grid
          item
          xs={4}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "baseline",
          }}
        >
          {additionalActions}
          {numSelected === 1 ? (
            <Tooltip title="Modifier">
              <IconButton onClick={onEdit}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <></>
          )}
          {numSelected > 0 ? (
            <Tooltip title="Supprimer">
              <IconButton onClick={onDelete}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Button variant="contained" onClick={onCreate}>
              Ajouter
            </Button>
          )}
        </Grid>
      </Grid>
      <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
    </Stack>
  );
}
