import { QueryClient } from "@tanstack/react-query";

import fetchCustomQuery from "../common/fetchCustomQuery";
import getCustomQueryData from "../common/getCustomQueryData";
import devicesQuery from "../devices/devices.query";
import networksQuery from "../networks/networks.query";

export default function homeLoader(queryClient: QueryClient) {
  return async () => {
    const devQuery = devicesQuery();
    const netQuery = networksQuery();
    return {
      devices:
        getCustomQueryData(queryClient, devQuery) ??
        (await fetchCustomQuery(queryClient, devQuery)),
      networks:
        getCustomQueryData(queryClient, netQuery) ??
        (await fetchCustomQuery(queryClient, netQuery)),
    };
  };
}

export type HomeLoaderData = Awaited<ReturnType<ReturnType<typeof homeLoader>>>;
