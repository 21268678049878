import * as React from "react";

import { AxiosError } from "axios";
import { useRouteError } from "react-router-dom";
import { Link } from "react-router-dom";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import { ApiError } from "../../../interfaces/ApiError";
import { axiosErrorToArray } from "../../../utils/axiosErrorToArray";

export default function ErrorPage() {
  const error = useRouteError();

  const baseMessage = (err: any) =>
    (err?.statusText as string) ?? (err?.message as string) ?? "Unknown error";

  let [messages, setMessages] = React.useState([baseMessage(error)]);
  axiosErrorToArray(error as AxiosError<ApiError, any>)
    .then((errors) => {
      const newMessages = [baseMessage(error)].concat(errors);
      if (newMessages.join("\n") !== messages.join("\n")) {
        console.log("new message", newMessages);
        setMessages(newMessages);
      }
    })
    .catch(() => console.error("rejected"));

  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#f0f0f0",
      }}
    >
      <Paper
        elevation={1}
        sx={{
          display: "flex",
          justifyContent: "space-around",
          flexWrap: "wrap",
          padding: theme.spacing(1),
          minWidth: "50%",
        }}
      >
        <Typography
          variant="h1"
          sx={{ color: "primary.main", margin: theme.spacing(10, 2) }}
        >
          Oups !
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            "> *": {
              padding: theme.spacing(1),
            },
          }}
        >
          <Typography>Désolé, une erreur s'est produite.</Typography>
          <ul>
            {messages.map((m, i) => {
              return (
                <li key={`error-${i}`}>
                  <Typography fontSize={12}>{m}</Typography>
                </li>
              );
            })}
          </ul>

          <Link to="/">
            <Typography>Revenir à l'accueil</Typography>
          </Link>
        </Box>
      </Paper>
    </Box>
  );
}
