import * as React from "react";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

export interface HomeCardProps {
  primary: string;
  secondary: string;
}

export default function HomeCard({ primary, secondary }: HomeCardProps) {
  const theme = useTheme();

  return (
    <Card
      sx={{
        width: 175,
        height: 175,
        backgroundColor: theme.palette.primary.main,
        color: "primary.contrastText",
        textAlign: "center",
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          height: "100%",
          width: "100%",
          flexDirection: "column",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <Typography variant="h2">{primary}</Typography>
        <Typography sx={{ fontSize: "1rem" }}>{secondary}</Typography>
      </CardContent>
    </Card>
  );
}
