import * as React from "react";

import dayjs from "dayjs";
import { useLoaderData } from "react-router-dom";

import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";

import { useQueryClient } from "@tanstack/react-query";

import DeleteConfirmationDialog from "../../../components/Dialogs/DeleteConfirmationDialog";
import EntityGrid, {
  HeadCell,
} from "../../../components/EntityGrid/EntityGrid";
import EntityGridToolbar from "../../../components/EntityGrid/EntityGridToolbar";
import ErrorBox from "../../../components/ErrorBox/ErrorBox";
import Devices from "../../../interfaces/Devices";
import useCustomMutation from "../../../network/common/useCustomMutation";
import useCustomQuery from "../../../network/common/useCustomQuery";
import actionsQuery from "../../../network/configuration/actions.query";
import deleteDeviceMutation from "../../../network/devices/delete.mutation";
import { DevicesLoaderData } from "../../../network/devices/devices.loader";
import devicesQuery from "../../../network/devices/devices.query";
import downloadDeviceConfig from "../../../network/devices/downloadConfig";
import downloadMultipleDeviceConfig from "../../../network/devices/downloadMultipleConfig";
import { deleteMultipleIds } from "../../../utils/deleteMultipleIds";
import filterArray from "../../../utils/filterArray";
import DevicesForm from "./DevicesForm";

const columns: HeadCell<{
  id: number;
  name: string;
  type: string;
  ip: string;
  organization: string;
  network: string;
  endDate: string;
}>[] = [
  { id: "id", label: "ID" },
  { id: "name", label: "Nom" },
  { id: "type", label: "Type" },
  { id: "ip", label: "Adresse IP" },
  { id: "organization", label: "Organisation" },
  { id: "network", label: "Réseau" },
  { id: "endDate", label: "Fin de validité" },
];

export default function DevicesPage() {
  const initialData = useLoaderData() as DevicesLoaderData;
  const { data } = useCustomQuery(devicesQuery(), { initialData });
  const queryClient = useQueryClient();

  const [selected, setSelected] = React.useState<readonly number[]>([]);

  const mapDataToRows = (devices: Devices) => {
    return devices.map((d) => {
      return {
        id: d.id,
        name: d.name,
        type: d.type.charAt(0).toUpperCase() + d.type.slice(1),
        ip: d.ip,
        network: d.network.name,
        organization: d.organization.name,
        endDate: dayjs(d.endDate).format("DD/MM/YYYY"),
      };
    });
  };

  const [rows, setRows] = React.useState(mapDataToRows(data.data));

  const [openDialog, setOpenDialog] = React.useState(false);

  const [errors, setErrors] = React.useState<string[]>([]);

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleEdit = () => {
    setOpenDialog(true);
  };

  // Delete
  const [openDeleteDialog, setopenDeleteDialog] = React.useState(false);
  const deleteMutation = useCustomMutation(deleteDeviceMutation);
  const handleDelete = () => {
    setopenDeleteDialog(true);
  };
  const deleteIds = async (ids: readonly number[]) => {
    const mutationErrors = await deleteMultipleIds(
      deleteMutation,
      "Appareil",
      ids
    );
    setErrors(mutationErrors);
    queryClient.invalidateQueries({ queryKey: devicesQuery().key });
    queryClient.invalidateQueries({ queryKey: actionsQuery().key });
    setSelected([]);
  };

  // Filter
  const [filter, setFilter] = React.useState("");
  const onFilterChanged = (filter: string) => {
    setFilter(filter);
  };
  React.useEffect(() => {
    setRows(filterArray(filter, mapDataToRows(data.data)));
  }, [filter, data]);

  const onDownload = async () => {
    const dev = data.data.filter(
      (d) => selected.find((s) => s === d.id) !== undefined
    );
    if (dev.length === 1) {
      setErrors(await downloadDeviceConfig(dev[0]));
    } else if (dev.length > 1) {
      setErrors(await downloadMultipleDeviceConfig(dev));
    }
  };

  return (
    <Stack flexGrow={1}>
      <EntityGridToolbar
        name="Appareils"
        selectedIDs={selected}
        onCreate={handleClickOpen}
        onEdit={handleEdit}
        onDelete={handleDelete}
        filter={filter}
        setFilter={onFilterChanged}
        additionalActions={
          selected.length > 0 ? (
            <Tooltip title="Télécharger">
              <IconButton onClick={onDownload}>
                <FileDownloadIcon />
              </IconButton>
            </Tooltip>
          ) : undefined
        }
      />

      <EntityGrid
        rows={rows}
        columns={columns}
        defaultSortingColumn="id"
        selected={selected}
        setSelected={setSelected}
      />

      <Box sx={{ flexGrow: 1 }}></Box>

      <ErrorBox errors={errors} setErrors={setErrors} />

      <DevicesForm
        open={openDialog}
        setOpen={setOpenDialog}
        device={
          selected.length === 1
            ? data.data.find((n) => n.id === selected[0])
            : undefined
        }
      />

      <DeleteConfirmationDialog
        idsToDelete={selected}
        deleteIds={deleteIds}
        open={openDeleteDialog}
        setOpen={setopenDeleteDialog}
      />
    </Stack>
  );
}
