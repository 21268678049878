import { Outlet } from "react-router-dom";

import Box from "@mui/material/Box";

import NavBar from "../components/Layouts/NavBar";

export default function Root() {
  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
      }}
    >
      <NavBar />
      <Outlet />
    </Box>
  );
}
