import { AxiosRequestConfig } from "axios";

import { ApiError } from "../../interfaces/ApiError";
import { Organization } from "../../interfaces/Organizations";
import { MutationReturnType } from "../common/useCustomMutation";

export default function createOrganizationMutation(data: {
  name: string;
}): MutationReturnType<Organization, ApiError> {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "/organizations",
    data: data,
  };

  return { config };
}
