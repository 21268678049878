import { AxiosRequestConfig } from "axios";

import { ApiError } from "../../interfaces/ApiError";
import { MutationReturnType } from "../common/useCustomMutation";

export default function applyConfigurationMutation(data: {
  id: number;
}): MutationReturnType<void, ApiError> {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "/configuration/apply",
    data: data,
  };

  return { config };
}
