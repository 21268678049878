import * as React from "react";

import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";

import { yupResolver } from "@hookform/resolvers/yup";
import { useQueryClient } from "@tanstack/react-query";

import ErrorBox from "../../../components/ErrorBox/ErrorBox";
import { Network } from "../../../interfaces/Networks";
import useCustomMutation from "../../../network/common/useCustomMutation";
import useCustomQuery from "../../../network/common/useCustomQuery";
import actionsQuery from "../../../network/configuration/actions.query";
import createNetworkMutation from "../../../network/networks/create.mutation";
import networksQuery from "../../../network/networks/networks.query";
import nextAvailablePortQuery from "../../../network/networks/nextAvailablePort.query";
import portRangeQuery from "../../../network/networks/portRange.query";
import updateNetworkMutation from "../../../network/networks/update.mutation";
import organizationsQuery from "../../../network/organizations/organizations.query";
import { axiosErrorToArray } from "../../../utils/axiosErrorToArray";

interface FormData {
  name: string;
  ipRange: string;
  port: number;
  organizations: number[];
}

const cidrRegex =
  /^(?:(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])\.){3}(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])\/(?:3[0-2]|[12][0-9]|[0-9])$/;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 300,
    },
  },
};

interface NetworkFormProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  network?: Network;
}
export default function NetworkForm({
  open,
  setOpen,
  network,
}: NetworkFormProps) {
  const [errors, setErrors] = React.useState<string[]>([]);
  const queryClient = useQueryClient();
  const createMutation = useCustomMutation(createNetworkMutation);
  const updateMutation = useCustomMutation(updateNetworkMutation);

  const nextAvailablePortData = useCustomQuery(nextAvailablePortQuery());
  const portRangeData = useCustomQuery(portRangeQuery());
  const organizationsData = useCustomQuery(organizationsQuery());

  const portMin = portRangeData.isSuccess ? portRangeData.data.data.portMin : 0;
  const portMax = portRangeData.isSuccess
    ? portRangeData.data.data.portMax
    : 65535;

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Un nom est requis"),
    ipRange: Yup.string()
      .matches(cidrRegex, "Format incorrect (écriture CIDR attendue)")
      .required("Un sous-réseau IP est requis"),
    port: Yup.number()
      .min(portMin, `Le port doit être supérieur ou égale à ${portMin}`)
      .max(portMax, `Le port doit être inférieur ou égale à ${portMax}`)
      .required("Un port est requis"),
    organizations: Yup.array()
      .of(Yup.number())
      .required("Au moins une organisation doit être choisie"),
  });

  const { handleSubmit, reset, control, setValue } = useForm<FormData>({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data: FormData) => {
    if (network === undefined) {
      createMutation.mutate(data, {
        onSuccess: (data) => {
          queryClient.invalidateQueries({ queryKey: networksQuery().key });
          queryClient.invalidateQueries({ queryKey: actionsQuery().key });
          handleClose();
        },
        onError: async (error) => {
          setErrors(await axiosErrorToArray(error));
        },
      });
    } else {
      updateMutation.mutate(
        { id: network.id, ...data },
        {
          onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: networksQuery().key });
            queryClient.invalidateQueries({ queryKey: actionsQuery().key });
            handleClose();
          },
          onError: async (error) => {
            setErrors(await axiosErrorToArray(error));
          },
        }
      );
    }
  };

  const handleClose = () => {
    reset();
    setErrors([]);
    setOpen(false);
  };

  React.useEffect(() => {
    if (network === undefined) {
      setValue("name", "");
      setValue("ipRange", "");
      setValue(
        "port",
        nextAvailablePortData.isSuccess
          ? nextAvailablePortData.data.data.availablePort
          : 1
      );
      setValue("organizations", []);
    } else {
      setValue("name", network.name);
      setValue("ipRange", network.ipRange);
      setValue("port", network.port);
      setValue(
        "organizations",
        network.organizations.map((o) => o.id)
      );
    }
  }, [network, nextAvailablePortData, setValue]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>
        {network === undefined
          ? "Ajout d'un réseau"
          : "Modification d'un réseau"}
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Controller
                name="name"
                control={control}
                defaultValue={""}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    required
                    label="Nom"
                    fullWidth
                    margin="dense"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="ipRange"
                control={control}
                defaultValue={""}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    required
                    label="Sous-réseau IP"
                    fullWidth
                    margin="dense"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="port"
                control={control}
                defaultValue={
                  nextAvailablePortData.isSuccess
                    ? nextAvailablePortData.data.data.availablePort
                    : 1
                }
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    required
                    label="Port"
                    type="number"
                    fullWidth
                    margin="dense"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl required fullWidth margin="dense">
                <InputLabel id="select-orgs-label">Organisations</InputLabel>
                <Controller
                  name="organizations"
                  control={control}
                  defaultValue={[]}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Select
                      labelId="select-orgs-label"
                      id="select-orgs"
                      multiple
                      fullWidth
                      value={value}
                      onChange={onChange}
                      input={<OutlinedInput label="Organisations" />}
                      MenuProps={MenuProps}
                    >
                      {organizationsData.isSuccess ? (
                        organizationsData.data.data.map((org) => (
                          <MenuItem key={org.id} value={org.id}>
                            {org.name}
                          </MenuItem>
                        ))
                      ) : (
                        <></>
                      )}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12}>
              <ErrorBox errors={errors} setErrors={setErrors} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Annuler
          </Button>
          <Button type="submit" autoFocus>
            {network === undefined ? "Ajouter" : "Modifier"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
