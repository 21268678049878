import * as React from "react";

import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { useAuth } from "../../../contexts/AuthContext";
import useCustomMutation from "../../../network/common/useCustomMutation";
import loginMutation from "../../../network/login/login.mutation";

export default function LoginPage() {
  const theme = useTheme();
  const { setToken } = useAuth();

  const [credentials, setCredentials] = React.useState({
    username: "",
    password: "",
  });

  const [error, setError] = React.useState("");

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCredentials({ ...credentials, [event.target.name]: event.target.value });
  };

  const mutation = useCustomMutation(loginMutation);

  const handleClick = () => {
    if (credentials.username && credentials.password) {
      mutation.mutate(
        {
          username: credentials.username,
          password: credentials.password,
        },
        {
          onSuccess: (data) => {
            localStorage.setItem("token", data.data.access_token);
            setToken(data.data.access_token);
            setError("");
          },
          onError: () => setError("Échec de la connexion"),
        }
      );
    } else {
      setError("Veuillez renseigner un utilisateur et un mot de passe");
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: theme.palette.back.main,
        }}
      >
        <Typography
          variant="h1"
          sx={{
            color: theme.palette.back.contrastText,
            fontSize: "2rem",
            margin: theme.spacing(0, 0, 4, 0),
            fontWeight: 600,
          }}
        >
          OpenVPN Manager
        </Typography>
        <Paper
          elevation={1}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            padding: theme.spacing(4),
            minWidth: "50%",
          }}
        >
          <TextField
            label="Utilisateur"
            value={credentials.username}
            name="username"
            onChange={onChange}
          />
          <TextField
            label="Mot de passe"
            type="password"
            name="password"
            value={credentials.password}
            onChange={onChange}
            sx={{
              margin: theme.spacing(2, 0, 1, 0),
            }}
          />
          <Button
            variant="contained"
            onClick={handleClick}
            sx={{
              margin: theme.spacing(1, 0, 2, 0),
            }}
          >
            Connexion
          </Button>
          {error ? (
            <Alert
              severity="error"
              onClose={() => {
                setError("");
              }}
            >
              {error}
            </Alert>
          ) : (
            <></>
          )}
        </Paper>
      </Box>
    </>
  );
}
