export const ip4ToInt = (ip: string) =>
  ip.split(".").reduce((int, oct) => (int << 8) + parseInt(oct, 10), 0) >>> 0;

export function intToIp4(ip: number) {
  const parts = new Array<string>(4);
  for (let i = 0; i < 4; ++i) {
    parts[3 - i] = (ip % 256).toString();
    ip >>>= 8;
  }
  return parts.join(".");
}
