import { AxiosRequestConfig } from "axios";

import { ApiError } from "../../interfaces/ApiError";
import { Organization } from "../../interfaces/Organizations";
import { MutationReturnType } from "../common/useCustomMutation";

export default function updateNetworkMutation(data: {
  id: number;
  name: string;
  ipRange: string;
  port: number;
  organizations: number[];
}): MutationReturnType<Organization, ApiError> {
  const config: AxiosRequestConfig = {
    method: "patch",
    url: `/networks/${data.id}`,
    data: {
      name: data.name,
      ipRange: data.ipRange,
      port: data.port,
      organizations: data.organizations,
    },
  };

  return { config };
}
