import { AxiosRequestConfig } from "axios";

import { ApiError } from "../../interfaces/ApiError";
import { Organization } from "../../interfaces/Organizations";
import { MutationReturnType } from "../common/useCustomMutation";
import { CreateDeviceDto } from "./create.mutation";

export default function createMultipleDeviceMutation(
  data: CreateDeviceDto[]
): MutationReturnType<Organization, ApiError> {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "/devices/multiple",
    data: { devices: data },
  };

  return { config };
}
