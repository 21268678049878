import {
  createBrowserRouter,
  Navigate,
  RouterProvider as ReactRouterProvider,
} from "react-router-dom";

import { queryClient } from "../App";
import { useAuth } from "../contexts/AuthContext";
import configurationLoader from "../network/configuration/configuration.loader";
import devicesLoader from "../network/devices/devices.loader";
import homeLoader from "../network/home/home.loader";
import networksLoader from "../network/networks/networks.loader";
import organizationsLoader from "../network/organizations/organizations.loader";
import ConfigurationPage from "../views/Configuration/ConfigurationPage/ConfigurationPage";
import DevicesPage from "../views/Devices/DevicesPage/DevicesPage";
import ErrorPage from "../views/Error/ErrorPage/ErrorPage";
import HomePage from "../views/Home/HomePage/HomePage";
import LoginPage from "../views/Login/LoginPage/LoginPage";
import LogoutPage from "../views/Login/LogoutPage/LogoutPage";
import NetworksPage from "../views/Networks/NetworksPage/NetworksPage";
import OrganizationsPage from "../views/Organizations/OrganizationsPage/OrganizationsPage";
import Root from "./Root";

export default function RouterProvider() {
  const { token } = useAuth();
  const isLoggedIn = token !== "";

  const router = createBrowserRouter([
    {
      path: "/",
      element: isLoggedIn ? <Root /> : <Navigate to="/login" />,
      errorElement: <ErrorPage />,
      children: isLoggedIn
        ? [
            {
              path: "/",
              element: <HomePage />,
              loader: homeLoader(queryClient),
            },
            {
              path: "organizations/",
              element: <OrganizationsPage />,
              loader: organizationsLoader(queryClient),
            },
            {
              path: "networks/",
              element: <NetworksPage />,
              loader: networksLoader(queryClient),
            },
            {
              path: "devices/",
              element: <DevicesPage />,
              loader: devicesLoader(queryClient),
            },
            {
              path: "configuration/",
              element: <ConfigurationPage />,
              loader: configurationLoader(queryClient),
            },
          ]
        : [],
    },
    {
      path: "/logout",
      element: <LogoutPage />,
    },
    {
      path: "/login",
      element: isLoggedIn ? <Navigate to="/" /> : <LoginPage />,
    },
  ]);

  return <ReactRouterProvider router={router} />;
}
