import * as React from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

interface DeleteConfirmationDialogProps {
  idsToDelete: readonly number[];
  deleteIds: (ids: readonly number[]) => void;
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function DeleteConfirmationDialog({
  idsToDelete,
  deleteIds,
  open,
  setOpen,
}: DeleteConfirmationDialogProps) {
  const handleClose = () => {
    setOpen(false);
  };

  const doIt = () => {
    deleteIds(idsToDelete);
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Confirmation de suppression
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {`Vous êtes sur le point de supprimer ${idsToDelete.length} élèment${
            idsToDelete.length > 1 ? "s" : ""
          }.`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={doIt}>Confirmer</Button>
        <Button onClick={handleClose} autoFocus>
          Annuler
        </Button>
      </DialogActions>
    </Dialog>
  );
}
