import { AxiosRequestConfig } from "axios";

import { ApiError } from "../../interfaces/ApiError";
import { Organization } from "../../interfaces/Organizations";
import { MutationReturnType } from "../common/useCustomMutation";

export default function updateOrganizationMutation(data: {
  id: number;
  name: string;
}): MutationReturnType<Organization, ApiError> {
  const config: AxiosRequestConfig = {
    method: "patch",
    url: `/organizations/${data.id}`,
    data: { name: data.name },
  };

  return { config };
}
