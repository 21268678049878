import { AxiosRequestConfig } from "axios";
import { Dayjs } from "dayjs";

import { ApiError } from "../../interfaces/ApiError";
import { DeviceType } from "../../interfaces/Devices";
import { Organization } from "../../interfaces/Organizations";
import { MutationReturnType } from "../common/useCustomMutation";
export interface CreateDeviceDto {
  name: string;
  ip: string;
  organization: number;
  network: number;
  endDate: Dayjs;
  type: DeviceType;
}

export default function createDeviceMutation(
  data: CreateDeviceDto
): MutationReturnType<Organization, ApiError> {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "/devices",
    data: {
      name: data.name,
      ip: data.ip,
      organization: data.organization,
      network: data.network,
      endDate: data.endDate.toISOString(),
      type: data.type,
    },
  };

  return { config };
}
