import { AxiosRequestConfig } from "axios";

import AvailablePort from "../../interfaces/AvailablePort";
import { QueryReturnType } from "../common/useCustomQuery";

export default function nextAvailablePortQuery(): QueryReturnType<
  string[],
  AvailablePort
> {
  const config: AxiosRequestConfig = {
    method: "get",
    url: "/networks/nextAvailablePort",
  };
  return { key: ["networks", "nextAvailablePort"], config };
}
