import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

import {
  DefinedUseQueryResult,
  QueryKey,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import { config as apiConfig } from "../../utils/config";

// Disable the warning about the unused TData type.
// The type is actually used to pass the type of the
// axios response to the useCustomQuery hook.
// eslint-disable-next-line
export interface QueryReturnType<TQueryKey, TData> {
  key: TQueryKey;
  config: AxiosRequestConfig;
}

export function generateAxiosConfig(
  config: AxiosRequestConfig
): AxiosRequestConfig {
  return {
    ...config,
    baseURL: apiConfig.API_ROOT,
    withCredentials: true,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };
}

// See https://github.com/TanStack/query/pull/3834/files
function useCustomQuery<TQueryKey extends QueryKey, TData>(
  queryConfig: QueryReturnType<TQueryKey, TData>,
  options?: Omit<
    UseQueryOptions<
      AxiosResponse<TData>,
      unknown,
      AxiosResponse<TData>,
      TQueryKey
    >,
    "queryKey" | "queryFn" | "initialData"
  > & { initialData: AxiosResponse<TData> | (() => AxiosResponse<TData>) }
): DefinedUseQueryResult<AxiosResponse<TData>, unknown>;

function useCustomQuery<TQueryKey extends QueryKey, TData>(
  queryConfig: QueryReturnType<TQueryKey, TData>,
  options?: UseQueryOptions<
    AxiosResponse<TData>,
    unknown,
    AxiosResponse<TData>,
    TQueryKey
  >
): UseQueryResult<AxiosResponse<TData>, unknown> {
  const { key, config } = queryConfig;

  const finalConfig = generateAxiosConfig(config);

  return useQuery(key, () => axios(finalConfig), options);
}

export default useCustomQuery;
