import * as React from "react";

import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

interface ErrorBoxProps {
  errors: string[];
  setErrors: (err: string[]) => void;
}

export default function ErrorBox({ errors, setErrors }: ErrorBoxProps) {
  const style = errors.length === 0 ? { display: "none" } : {};
  return (
    <Alert
      severity="error"
      onClose={() => {
        setErrors([]);
      }}
      sx={style}
    >
      <AlertTitle>Erreurs</AlertTitle>
      <ul>
        {errors.map((m, i) => (
          <li key={`error-msg-${i}`}>{m}</li>
        ))}
      </ul>
    </Alert>
  );
}
