import { AxiosRequestConfig } from "axios";

import { MutationReturnType } from "../common/useCustomMutation";

interface LoginReturnInterface {
  access_token: string;
}

export default function loginMutation(data: {
  username: string;
  password: string;
}): MutationReturnType<LoginReturnInterface> {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "/auth/login",
    data: data,
  };

  return { config };
}
