import { AxiosRequestConfig } from "axios";

import { ApiError } from "../../interfaces/ApiError";
import { MutationReturnType } from "../common/useCustomMutation";

export default function deleteOrganizationMutation(data: {
  id: number;
}): MutationReturnType<void, ApiError> {
  const config: AxiosRequestConfig = {
    method: "delete",
    url: `/organizations/${data.id}`,
    data: data,
  };

  return { config };
}
