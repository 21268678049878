import * as React from "react";

import ArticleIcon from "@mui/icons-material/Article";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import RouterIcon from "@mui/icons-material/Router";
import SecurityIcon from "@mui/icons-material/Security";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import { SxProps, Theme, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import { Action } from "../../../interfaces/Actions";

export default function ActionDetails({
  action,
  sx = [],
}: {
  action: Action;
  sx?: SxProps<Theme>;
}) {
  const theme = useTheme();
  let icon: React.ReactNode = undefined;
  let shortDescription = "";

  const iconStyle = {
    color: "primary.contrastText",
    backgroundColor: "primary.main",
    borderRadius: "100%",
    padding: "4px",
    fontSize: "2rem",
  };

  switch (action.type) {
    case "file":
      icon = <ArticleIcon sx={iconStyle} />;
      const path: string = action.value.path;
      switch (action.name) {
        case "chmod":
          shortDescription = `Changement des droits de "${path}"`;
          break;
        case "chown":
          shortDescription = `Changement du propriétaire de "${path}"`;
          break;
        case "create":
          shortDescription = `Création du fichier "${path}"`;
          break;
        case "delete":
          shortDescription = `Suppression du fichier "${path}"`;
          break;
        case "move":
          shortDescription = `Renommage du fichier "${path}"`;
          break;
        case "write":
          shortDescription = `Édition du fichier "${path}"`;
          break;
        default:
          shortDescription = `Action de type ${action.name} sur le fichier "${path}"`;
          break;
      }
      break;
    case "certificate":
      icon = <SecurityIcon sx={iconStyle} />;
      const fileName: string = action.value.fileName;
      switch (action.name) {
        case "create":
          shortDescription = `Création du certificat "${action.value.type}-${action.value.id}"`;
          break;
        case "revoke":
          shortDescription = `Révocation du certificat "${fileName}"`;
          break;
        case "delete":
          shortDescription = `Mise à jour de la liste des certificats révoqués`;
          break;
        default:
          shortDescription = `Action de type ${action.name} sur le certificat"${fileName}"`;
          break;
      }
      break;
    case "ports":
      icon = <CompareArrowsIcon sx={iconStyle} />;
      shortDescription = "Édition des ports ouverts sur le firewall";
      break;
    case "services":
      icon = <RouterIcon sx={iconStyle} />;
      switch (action.name) {
        case "create":
          shortDescription = "Édition des serveurs vpn lancés";
          break;
        case "write":
          shortDescription = "Reconfiguration des serveurs vpn lancés";
          break;
        default:
          shortDescription = "Modification des serveurs vpn lancés";
          break;
      }
      break;
    case "monitoring":
      icon = <QueryStatsIcon sx={iconStyle} />;
      switch (action.name) {
        case "write":
          shortDescription = "Mise à jour de la configuration du monitoring";
          break;
        case "create":
          shortDescription = "Création d'un utilisateur Grafana";
          break;
        case "delete":
          shortDescription = "Suppression d'un utilisateur Grafana";
          break;
        default:
          shortDescription = "Mise à jour du monitoring";
          break;
      }
      break;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        ...sx,
      }}
    >
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              ">*": { margin: theme.spacing(0, 1) },
            }}
          >
            {icon}
            <Typography>{shortDescription}</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>{`Raison : ${action.reason}`}</Typography>
          <Box
            component="pre"
            sx={{
              backgroundColor: "back.main",
              borderRadius: "16px",
              padding: theme.spacing(1),
              whiteSpace: "pre-wrap",
            }}
          >
            {JSON.stringify(action.value, null, 2)}
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
