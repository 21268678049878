import * as React from "react";

import { Link } from "react-router-dom";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import { useAuth } from "../../../contexts/AuthContext";

export default function LogoutPage() {
  const theme = useTheme();

  const { setToken } = useAuth();

  setToken("");
  localStorage.removeItem("token");

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#f0f0f0",
      }}
    >
      <Paper
        elevation={1}
        sx={{
          display: "flex",
          justifyContent: "space-around",
          flexWrap: "wrap",
          padding: theme.spacing(1),
          minWidth: "50%",
        }}
      >
        <Typography
          variant="h1"
          sx={{ color: "primary.main", margin: theme.spacing(10, 1) }}
        >
          Au revoir
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            "> *": {
              padding: theme.spacing(1),
            },
          }}
        >
          <Typography>Vous avez été deconnecté</Typography>
          <Link to="/login">
            <Typography>Se reconnecter</Typography>
          </Link>
        </Box>
      </Paper>
    </Box>
  );
}
