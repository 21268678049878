import axios, { AxiosError } from "axios";

import { ApiError } from "../../interfaces/ApiError";
import { Device } from "../../interfaces/Devices";
import { axiosErrorToArray } from "../../utils/axiosErrorToArray";
import { generateAxiosConfig } from "../common/useCustomQuery";

export default async function downloadDeviceConfig(device: Device) {
  let res: any;
  try {
    res = await axios(
      generateAxiosConfig({
        method: "get",
        url: `devices/${device.id}/config`,
        responseType: "blob",
      })
    );
  } catch (err) {
    if (err instanceof AxiosError<ApiError, any>) {
      return await axiosErrorToArray(err);
    }
    throw err;
  }
  const filename = `${String(device.id).padStart(4, "0")}-${device.name.replace(
    /[^A-Za-z0-9]/g,
    "_"
  )}.conf`;

  // create file link in browser's memory
  const href = URL.createObjectURL(res.data);

  // create "a" HTML element with href to file & click
  const link = document.createElement("a");
  link.href = href;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();

  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
  URL.revokeObjectURL(href);

  // Return no errors
  return [];
}
