import * as React from "react";

import dayjs, { Dayjs } from "dayjs";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";

import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { yupResolver } from "@hookform/resolvers/yup";
import { useQueryClient } from "@tanstack/react-query";

import ErrorBox from "../../../components/ErrorBox/ErrorBox";
import { Device, DeviceType } from "../../../interfaces/Devices";
import useCustomMutation from "../../../network/common/useCustomMutation";
import useCustomQuery from "../../../network/common/useCustomQuery";
import actionsQuery from "../../../network/configuration/actions.query";
import createDeviceMutation, {
  CreateDeviceDto,
} from "../../../network/devices/create.mutation";
import createMultipleDeviceMutation from "../../../network/devices/createMultiple.mutation";
import devicesQuery from "../../../network/devices/devices.query";
import updateDeviceMutation from "../../../network/devices/update.mutation";
import networksQuery from "../../../network/networks/networks.query";
import organizationsQuery from "../../../network/organizations/organizations.query";
import { axiosErrorToArray } from "../../../utils/axiosErrorToArray";
import { intToIp4, ip4ToInt } from "../../../utils/ip";

interface FormData {
  name: string;
  ip: string;
  organization: number;
  network: number;
  endDate: Dayjs;
  type: number;
}

const ipRegex =
  /^(?:(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])\.){3}(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])$/;

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Un nom est requis"),
  ip: Yup.string()
    .matches(ipRegex, "Format incorrect (IP attendue)")
    .required("Un sous-réseau IP est requis"),
  organization: Yup.number().required("Une organisation est requise"),
  network: Yup.number().required("Un réseau est requis"),
  endDate: Yup.object()
    .test("is-dayjs", "La date de fin doit être une date valide", (value) => {
      if (!dayjs.isDayjs(value)) {
        return false;
      }
      return true;
    })
    .required("Une date de fin est requise"),
});

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 300,
    },
  },
};

interface DevicesFormProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  device?: Device;
}
export default function DevicesForm({
  open,
  setOpen,
  device,
}: DevicesFormProps) {
  const [errors, setErrors] = React.useState<string[]>([]);
  const createMutation = useCustomMutation(createDeviceMutation);
  const createMultipleMutation = useCustomMutation(
    createMultipleDeviceMutation
  );
  const updateMutation = useCustomMutation(updateDeviceMutation);
  const queryClient = useQueryClient();

  const networksData = useCustomQuery(networksQuery());
  const organizationsData = useCustomQuery(organizationsQuery());

  let defaultEndDate = dayjs().add(5, "years");

  const { handleSubmit, reset, control, setValue } = useForm<FormData>({
    resolver: yupResolver(validationSchema),
  });

  const [createMultiples, setCreateMultiples] = React.useState(false);
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCreateMultiples(event.target.checked);
  };
  const [createNumber, setCreateNumber] = React.useState(1);
  const handleNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCreateNumber(Number(event.target.value));
  };

  const onSubmit = (data: FormData) => {
    const dataWithType = {
      ...data,
      type: Object.values(DeviceType)[data.type],
    };
    if (device === undefined) {
      if (createMultiples) {
        const dataArray: CreateDeviceDto[] = [];
        for (let i = 0; i < createNumber; ++i) {
          dataArray.push({
            ...dataWithType,
            name: `${data.name}-${(i + 1).toString().padStart(3, "0")}`,
            ip: intToIp4(ip4ToInt(data.ip) + i),
          });
        }
        createMultipleMutation.mutate(dataArray, {
          onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: devicesQuery().key });
            queryClient.invalidateQueries({ queryKey: actionsQuery().key });
            handleClose();
          },
          onError: async (error) => {
            setErrors(await axiosErrorToArray(error));
          },
        });
      } else {
        createMutation.mutate(dataWithType, {
          onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: devicesQuery().key });
            queryClient.invalidateQueries({ queryKey: actionsQuery().key });
            handleClose();
          },
          onError: async (error) => {
            setErrors(await axiosErrorToArray(error));
          },
        });
      }
    } else {
      updateMutation.mutate(
        { id: device.id, ...dataWithType },
        {
          onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: devicesQuery().key });
            queryClient.invalidateQueries({ queryKey: actionsQuery().key });
            handleClose();
          },
          onError: async (error) => {
            setErrors(await axiosErrorToArray(error));
          },
        }
      );
    }
  };

  const handleClose = () => {
    setErrors([]);
    setCreateMultiples(false);
    setCreateNumber(1);
    reset();
    setOpen(false);
  };

  React.useEffect(() => {
    if (device === undefined) {
      setValue("name", "");
      setValue("ip", "");

      if (networksData.isSuccess && networksData.data.data.length > 0) {
        setValue("network", 1);
      } else {
        setValue("network", 0);
      }
      if (
        organizationsData.isSuccess &&
        organizationsData.data.data.length > 0
      ) {
        setValue("organization", 1);
      } else {
        setValue("organization", 0);
      }
      setValue("endDate", defaultEndDate);
      setValue("type", 0);
    } else {
      setValue("name", device.name);
      setValue("ip", device.ip);
      setValue("network", device.network.id);
      setValue("organization", device.organization.id);
      setValue("endDate", dayjs(device.endDate));
      setValue("type", Object.values(DeviceType).indexOf(device.type));
    }
  }, [device, defaultEndDate, setValue, networksData, organizationsData]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>
        {device === undefined
          ? "Ajout d'un appareil"
          : "Modification d'un appareil"}
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container spacing={1}>
            {device === undefined ? (
              <>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={createMultiples}
                        onChange={handleCheckboxChange}
                      />
                    }
                    label="Création multiple"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Nombre"
                    type="number"
                    inputProps={{
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                      min: "0",
                      step: "1",
                    }}
                    disabled={!createMultiples}
                    variant="outlined"
                    size="small"
                    value={createNumber}
                    onChange={handleNumberChange}
                  />
                </Grid>
                {createMultiples ? (
                  <Grid item xs={12}>
                    <Typography variant="body2" gutterBottom>
                      La création multiple permet de genérer de nombreux
                      appareils facilement. Un numéro sera ajouté à la fin du
                      nom de l'appareil (exemple "Device X" donnera "Device
                      X-001" puis "Device X-002" etc…). L'adresse IP sera
                      incrementé de 1 pour chaque appareil. L'organisation, le
                      réseau et la date de fin de validité seront identiques
                      pour tous les appareils.
                    </Typography>
                  </Grid>
                ) : (
                  <></>
                )}

                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </>
            ) : (
              <></>
            )}

            <Grid item xs={12}>
              <Controller
                name="name"
                control={control}
                defaultValue=""
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    required
                    label="Nom"
                    fullWidth
                    margin="dense"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="ip"
                control={control}
                defaultValue=""
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    required
                    label="Adresse IP"
                    fullWidth
                    margin="dense"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl required fullWidth margin="dense">
                <InputLabel id="select-type-label">Type</InputLabel>
                <Controller
                  name="type"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      labelId="select-type-label"
                      id="select-type"
                      value={value}
                      onChange={onChange}
                      input={<OutlinedInput label="Type" />}
                      MenuProps={MenuProps}
                    >
                      {Object.values(DeviceType).map((name, i) => (
                        <MenuItem key={i} value={i}>
                          {name.charAt(0).toUpperCase() + name.slice(1)}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl required fullWidth margin="dense">
                <InputLabel
                  id="select-org-label"
                  disabled={device !== undefined}
                >
                  Organisation
                </InputLabel>
                <Controller
                  name="organization"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      labelId="select-org-label"
                      id="select-org"
                      value={value}
                      onChange={onChange}
                      input={<OutlinedInput label="Organisation" />}
                      MenuProps={MenuProps}
                    >
                      {organizationsData.isSuccess ? (
                        organizationsData.data.data.map((org) => (
                          <MenuItem key={org.id} value={org.id}>
                            {org.name}
                          </MenuItem>
                        ))
                      ) : (
                        <></>
                      )}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl required fullWidth margin="dense">
                <InputLabel id="select-net-label">Réseau</InputLabel>
                <Controller
                  name="network"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      labelId="select-net-label"
                      id="select-net"
                      value={value}
                      onChange={onChange}
                      input={<OutlinedInput label="Réseau" />}
                      MenuProps={MenuProps}
                    >
                      {networksData.isSuccess ? (
                        networksData.data.data.map((net) => (
                          <MenuItem key={net.id} value={net.id}>
                            {net.name}
                          </MenuItem>
                        ))
                      ) : (
                        <></>
                      )}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="endDate"
                control={control}
                defaultValue={defaultEndDate}
                render={({ field: { onChange, value } }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      label="Date de fin"
                      inputFormat="DD/MM/YYYY"
                      value={value}
                      onChange={onChange}
                      renderInput={(params) => (
                        <TextField margin="dense" fullWidth {...params} />
                      )}
                      disabled={device !== undefined}
                    />
                  </LocalizationProvider>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <ErrorBox errors={errors} setErrors={setErrors} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Annuler
          </Button>
          <Button type="submit" autoFocus>
            {device === undefined ? "Ajouter" : "Modifier"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
