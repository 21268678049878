import { AxiosResponse } from "axios";

import { QueryClient, QueryKey } from "@tanstack/react-query";

import { QueryReturnType } from "./useCustomQuery";

export default function getCustomQueryData<TQueryKey extends QueryKey, TData>(
  queryClient: QueryClient,
  queryConfig: QueryReturnType<TQueryKey, TData>
): AxiosResponse<TData> | undefined {
  const { key } = queryConfig;

  return queryClient.getQueryData<AxiosResponse<TData>>(key);
}
