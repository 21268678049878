import BaseEntity from "./BaseEntity";
import { BaseNetwork } from "./Networks";
import { Organization } from "./Organizations";

export enum DeviceType {
  TELTONIKA = "teltonika",
  NETMODULE = "netmodule",
}

export interface Device extends BaseEntity {
  ip: string;
  organization: Organization;
  network: BaseNetwork;
  endDate: string;
  type: DeviceType;
}

type Devices = Device[];

export default Devices;
