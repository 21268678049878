import { AxiosRequestConfig } from "axios";

import { ApiError } from "../../interfaces/ApiError";
import { Organization } from "../../interfaces/Organizations";
import { MutationReturnType } from "../common/useCustomMutation";

export default function createNetworkMutation(data: {
  name: string;
  ipRange: string;
  port: number;
  organizations: number[];
}): MutationReturnType<Organization, ApiError> {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "/networks",
    data: data,
  };

  return { config };
}
