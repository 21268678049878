import { AxiosError } from "axios";

import { ApiError } from "../interfaces/ApiError";

export async function axiosErrorToArray(
  error: AxiosError<ApiError, any>
): Promise<string[]> {
  let message: string | string[] | undefined;
  if (error?.response?.data instanceof Blob) {
    const blob = error.response.data;
    if (blob.type === "application/json") {
      message = JSON.parse(await blob.text())?.message;
    }
  } else {
    message = error?.response?.data.message;
  }
  if (Array.isArray(message)) {
    return message;
  } else {
    return [message ?? "Erreur"];
  }
}
