import { AxiosRequestConfig } from "axios";
import { Dayjs } from "dayjs";

import { ApiError } from "../../interfaces/ApiError";
import { DeviceType } from "../../interfaces/Devices";
import { Organization } from "../../interfaces/Organizations";
import { MutationReturnType } from "../common/useCustomMutation";

export default function updateDeviceMutation(data: {
  id: number;
  name: string;
  ip: string;
  organization: number;
  network: number;
  endDate: Dayjs;
  type: DeviceType;
}): MutationReturnType<Organization, ApiError> {
  const config: AxiosRequestConfig = {
    method: "patch",
    url: `/devices/${data.id}`,
    data: {
      name: data.name,
      ip: data.ip,
      organization: data.organization,
      network: data.network,
      endDate: data.endDate.toISOString(),
      type: data.type,
    },
  };

  return { config };
}
